import React from "react";
import Footer from "src/components/Footer_uk";

class Page extends React.Component {
  render() {
    return (
      <div>
       
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/en_US/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/uk/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/uk/our-products/" target="_self">
                      Our Products
                    </a>
                    <ul>
                      <li>
                        <a href="/uk/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/protect_cool/">
                          Protect &amp; Cool
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>
                      <li>
                        <a href="/uk/our-products/instantglow/">Instant Glow</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/allergy/">Allergy</a>
                      </li>                      
                      <li>
                        <a href="/uk/our-products/wetskin/">Wet skin</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/mountain/">Mountain</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/aftersun/">After sun</a>
                      </li>                      
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/our-heritage/" target="_self">
                      Our Heritage
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/know_the_sun/" target="_self">
                      Know the sun
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/uk/where-to-buy/" target="_self">
                      Where to buy
                    </a>
                  </li>
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      countries
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/wetskin/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/wetskin/">UK</a>
                      </li>
                      <li>
                        <a href="/es/nuestros-productos/">ES</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/">PT</a>
                      </li>
                      <li>
                        <a href="/it/prodotti/wetskin/">IT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/wetskin/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt="PIZ BUIN WET SKIN®"
                    src="/assets/images/2017-pizbuin_1680x405px_WetSkin.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/wet_skin_960x405_topSmall.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN WET SKIN<sup>®</sup>
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Protection on wet and dry skin</h3>
                      <p>
                        When you‘re in the sun by the sea this summer you‘ll
                        never have to dry to regain lost protection immediately.
                        Just apply PIZ BUIN WET SKIN<sup>®</sup> Transparent Sun
                        Spray directly on your wet skin, right out of the water,
                        lie back, relax, and let the warmth of the sun dry you.
                        Thanks to the unique Aquaflex<sup>™</sup> Technology,
                        our Wet Skin Transparent Sun Spray cuts through water
                        absorbing directly on wet or dry skin. No white marks,
                        just effective UVA/UVB protection without needing to
                        dry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="transparent-sun-spray" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/bdcd8ba994d3fed82688dd3afcaf5ce4_f34.jpg" alt="PIZ BUIN WET SKIN®" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>TRANSPARENT SUN SPRAY</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>BENEFITS</h4>
                        <p>
                          PIZ BUIN WET SKIN<sup>®</sup>, the first line of
                          sunscreen from PIZ BUIN<sup>®</sup> designed to be
                          applied directly to wet and dry skin.
                        </p>
                        <div className="products-page__product__details__benefits__hidden">
                          <p>
                            When applied on wet skin, ordinary transparent sun
                            sprays can whiten and slide off, therefore
                            compromising your level of protection.
                          </p>
                          <div
                            style={{
                              clear: "both"
                            }}
                          >
                            <div
                              style={{
                                float: "left",
                                "margin-right": "20px"
                              }}
                            >
                              <img
                                src="/assets/images/wetskin_spray1.jpeg"
                                alt="wetskin_spray.jpg"
                                width="197"
                                height="139"
                              />
                              <p
                                style={{
                                  padding: "5px 20px 5px 0px",
                                  margin: "0px",
                                  "font-size": "0.7em"
                                }}
                              >
                                Ordinary transparent sun spray
                              </p>
                            </div>
                            <div
                              style={{
                                float: "left"
                              }}
                            >
                              <img
                                src="/assets/images/wetskin_spray2.jpeg"
                                alt="wetskin_spray.jpg"
                                width="197"
                                height="139"
                              />
                              <p
                                style={{
                                  padding: "5px 20px 5px 0px",
                                  margin: "0px",
                                  "font-size": "0.7em"
                                }}
                              >
                                PIZ BUIN WET SKIN<sup>®</sup>
                                <br /> Transparent Sun Spray
                              </p>
                            </div>
                            <div
                              style={{
                                clear: "both"
                              }}
                            >
                              {" "}
                            </div>
                            <div
                              style={{
                                clear: "both"
                              }}
                            >
                              PIZ BUIN WET SKIN<sup>®</sup> Transparent Sun
                              Spray is different. While ordinary transparent
                              sunscreen can compromise your protection by mixing
                              with water when applied on wet skin, PIZ BUIN WET
                              SKIN<sup>®</sup> Transparent Sun Spray cuts
                              directly through water, absorbing instantly into
                              the skin.
                            </div>
                          </div>
                          <p>
                            The formula hydrates the skin to make sure your skin
                            doesn’t dry out while you enjoy your time in the
                            sun.
                          </p>
                          <p>
                            PIZ BUIN WET SKIN<sup>®</sup> Transparent Sun Spray
                            is non-greasy, easy to apply and is extra water
                            resistant.
                          </p>
                          <p>
                            PIZ BUIN WET SKIN<sup>®</sup> Transparent Sun Spray
                            can be applied on wet or dry skin.
                          </p>
                        </div>
                        <button className="products-page__product__details__benefits__more">
                          Read more
                        </button>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>AVAILABLE IN SPF</h4>
                        <span className="factor factor15" />
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>DIRECTIONS</h4>
                        <p>
                          Apply generously and evenly before sun exposure. A
                          reduced quantity lowers the protection level
                          significantly. Reapply frequently, especially after
                          sweating, swimming or towelling. Avoid midday sun and
                          staying too long in the sun even while using
                          sunscreen. Keep babies and children out of direct
                          sunlight. Do not spray directly into the face. For use
                          on face, spray into hands and apply avoiding contact
                          with eyes. FLAMMABLE. Keep out of the reach of
                          children. Keep away from heat, hot surfaces, sparks,
                          open flames and other ignition sources. No smoking.
                        </p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              INGREDIENTS AND TECHNOLOGIES{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                ADVANCED UVA/UVB SUN FILTER SYSTEM
                                <div className="textBlock bottom">
                                  <p>
                                    As suncare specialists, we use innovative
                                    technologies to make sure you achieve the
                                    right balance of colour and protection
                                    whenever you enjoy the sun.
                                  </p>
                                  <p>
                                    Each of the advanced UVA/UVB sun filter
                                    systems is a powerful sun protection
                                    technology developed by PIZ BUIN<sup>®</sup>
                                    . They offer effective photostable,
                                    broad-spectrum UVA and UVB protection, and
                                    help achieve that PIZ BUIN<sup>®</sup>{" "}
                                    sunscreens comply with the latest European
                                    Commission recommendation on sunscreen
                                    products.
                                  </p>
                                  <p>
                                    All of the advanced UVA/UVB sun filter
                                    systems help to protect from UVA and UVB
                                    radiation.
                                  </p>
                                  <p>
                                    UVB rays - "burning rays" - harm the skin’s
                                    surface and are the main cause of sunburn.
                                  </p>
                                  <p>
                                    UVA rays – "aeging rays" - penetrate deeper
                                    and damage collagen and elastin, which can
                                    result in premature skin ageing and cancer.
                                  </p>
                                  <p>
                                    The advanced UVA/UVB sun filter systems
                                    contain both UVA and UVB filters to help
                                    protect against the sun and long term skin
                                    damage while also protecting against the
                                    more serious effects of UVA radiation. They
                                    cover most of the UVA / UVB spectrum,
                                    offering broadband protection for your skin.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" alt="ADVANCED UVA/UVB SUN FILTER SYSTEM" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                VITAMIN E
                                <div className="textBlock bottom">
                                  <p>
                                    Vitamin E is a powerful natural antioxidant
                                    that helps to protect from free radicals,
                                    which damage skin cells* and cause premature
                                    skin ageing.
                                    <br />
                                    <span className="footNote">
                                      <br />* In vitro
                                      <br />
                                      <br />
                                    </span>
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" alt="VITAMIN E" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                WET SKIN TECHNOLOGY
                                <div className="textBlock bottom">
                                  <p>
                                    Wet Skin is an innovative technology that
                                    allows sun protection to be applied directly
                                    on wet skin without compromising the level
                                    of protection.{" "}
                                  </p>
                                  <p>What does the Wet Skin Technology do?</p>
                                  <p>
                                    <strong>
                                      <em>Cuts through water:</em>
                                    </strong>
                                  </p>
                                  <p>
                                    Compared to ordinary transparent sunscreens
                                    PIZ BUIN WET SKIN<sup>®</sup> Transparent
                                    Sun Spray doesn’t mix with water on the skin
                                    but cuts directly through it. Thanks to the
                                    “golden ratio” between UV filters, oil and
                                    alcohol, the spray forms a separation
                                    between the formula and the water droplets.{" "}
                                  </p>
                                  <p>
                                    <strong>
                                      <em>
                                        Better application and adhesion to wet
                                        skin:
                                      </em>
                                    </strong>
                                  </p>
                                  <p>
                                    A combination of polyester, resin and
                                    silicone avoids peeling on wet skin and
                                    improves the application.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/cfd1bbda111e0a24f5706db2e4475dc7_f50.png" alt="Better application and adhesion to wet skin:" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                      <div className="products-page__product__details__where">
                        <a
                          href="/uk/where-to-buy/"
                          className="btn btn--uppercase"
                        >
                          Where To Buy
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/uk/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/ultra_light/" target="_self">
                      Ultra Light
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/protect_cool/" target="_self">
                      Protect &amp; Cool
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/allergy/" target="_self">
                      Allergy
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/onedaylong/" target="_self">
                      1 Day Long
                    </a>
                  </li>
                  <li>
                    <a
                      className="platform-links__active nav-path-selected"
                      href="/uk/our-products/wetskin/"
                      target="_self"
                    >
                      Wet skin
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/mountain/" target="_self">
                      Mountain
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/aftersun/" target="_self">
                      After sun
                    </a>
                  </li>
                  <li>
                    <a href="/uk/our-products/all_products/" target="_self">
                      All products
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <section id="social">
              <a
                href="https://www.instagram.com/PizBuin_UK/"
                target="_blank"
                className="inst"
              >
                &nbsp;
              </a>
            </section>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Read More');\n} else {\nbox.slideDown();\n$(this).text('Read Less');\n}\n});\n});\n"
          }}
        />
        <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
